define("ui/models/destinationrule", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    canHaveLabels: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    displaySubsetsString: Ember.computed('displaySubsets.[]', function () {
      return (Ember.get(this, 'displaySubsets') || []).join(', ');
    }),
    displaySubsets: Ember.computed('subsets.[]', function () {
      return (Ember.get(this, 'subsets') || []).map(function (s) {
        return s.name;
      });
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.istio.project-istio.destination-rules.detail.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.istio.project-istio.destination-rules.new', Ember.get(this, 'projectId'), {
          queryParams: {
            id: Ember.get(this, 'id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});